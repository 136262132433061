<template>

    <div>
        <div class="about-us-page">
            <section class="page-header-section">
                <div class="circle-pattern-3"><img src="/assets/images/banner/circle-pattern-3.svg" width="200" alt="">
                </div>
                <div class="container">
                    <div class="row">
                        <div class="col-md-8 col-lg-7"><!---->
                            <h2 class="heading">About Us</h2> <span class="desc d-block">KIPS and high-quality education
                                have been synonymous for the last three decades </span>
                        </div>
                    </div>
                </div>
            </section>
            <section class="aboutUS">
                <div class="about-inside">
                    <div class="container">
                        <div class="row">
                            <div class="aboutUSText col-lg-6">
                                <h2>Who we are</h2>
                                <p>
                                    KIPS, a premier educational establishment dedicated to nurturing minds from the
                                    earliest stages of learning through to the cusp of higher education, stands as an
                                    emblem of educational excellence. We pride ourselves on our holistic approach to
                                    learning, ensuring a smooth and enriching experience at all levels. Our standout
                                    specialty lies in our unparalleled preparation programs for various university entry
                                    tests, positioning us as the leading institute in this critical area within the
                                    country. Our commitment to excellence and our tailored approach to education ensure
                                    that our students not only excel academically but also achieve their aspirations for
                                    higher education and beyond. Embracing modern educational trends, we offer a robust
                                    online education platform that brings high-quality learning to students wherever
                                    they are.
                                </p>
                            </div>
                            <div class="aboutUSImg col-lg-6">
                                <img src="/assets/images/whoweare.jpg" alt="" width="630" height="550" />
                                <span class="experience-block">
                                    <strong>10</strong>
                                    <small>Year of Experience</small>
                                </span>
                            </div>

                        </div>
                    </div>

                </div>
                <div class="about-inside">
                    <div class="container">
                        <div class="row">
                            <div class="aboutUSText col-lg-6">
                                <h2>The Future of Education</h2>
                                <p>
                                    For the last 26 years, KIPS has been a beacon of knowledge, shaping the minds of
                                    students across various fields. In alignment with our commitment to innovation, we
                                    ventured into online education seven years ago, revolutionizing the way knowledge is
                                    shared and acquired. Harnessing the power of artificial intelligence, we are
                                    redefining the learning experience by offering AI-powered education that adapts to
                                    each student’s unique needs. The possibilities are endless as we step into the
                                    future of education, where advanced technology and academic excellence converge to
                                    unlock the full potential of every learner.
                                </p>

                            </div>
                            <div class="aboutUSImg col-lg-6">
                                <img src="/assets/images/futureOfEducation.jpg" alt="" width="630" height="630" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <!-- <section class="leaderBoardWrap">
                <div class="container">
                    <SsrCarousel  :show-arrows="true" :slides-per-page="1" :show-dots="true">
                        <div class="slide">
                            <div class="row">
                                <div class=" col-lg-6  col-xl-5 ">
                                    <h2>Onsectetur Curabitur </h2>
                                    <strong>Leaderboard</strong>
                                    <p>The leaderboard provides an understanding of where a student ranks in comparison
                                        with
                                        other
                                        students of the same program in a particular subject and the program as a whole.
                                        It
                                        encourages
                                        students to work harder to get to the top and boosts the confidence of those who
                                        get
                                        there.
                                    </p>
                                </div>
                                <div class=" col-lg-6  col-xl-7 ">
                                    <img src="/assets/images/onsector.png" width="895" height="674" alt="leader-board">
                                </div>
                            </div>
                        </div>
                        <div class="slide">
                            <div class="row">
                                <div class=" col-lg-6  col-xl-5 ">
                                    <h2>Onsectetur Curabitur </h2>
                                    <strong>Leaderboard</strong>
                                    <p>The leaderboard provides an understanding of where a student ranks in comparison
                                        with
                                        other
                                        students of the same program in a particular subject and the program as a whole.
                                        It
                                        encourages
                                        students to work harder to get to the top and boosts the confidence of those who
                                        get
                                        there.
                                    </p>
                                </div>
                                <div class=" col-lg-6  col-xl-7 ">
                                    <img src="/assets/images/onsector.png" width="895" height="674" alt="leader-board">
                                </div>
                            </div>
                        </div>
                        <div class="slide">
                            <div class="row">
                                <div class=" col-lg-6  col-xl-5 ">
                                    <h2>Onsectetur Curabitur </h2>
                                    <strong>Leaderboard</strong>
                                    <p>The leaderboard provides an understanding of where a student ranks in comparison
                                        with
                                        other
                                        students of the same program in a particular subject and the program as a whole.
                                        It
                                        encourages
                                        students to work harder to get to the top and boosts the confidence of those who
                                        get
                                        there.
                                    </p>
                                </div>
                                <div class=" col-lg-6  col-xl-7 ">
                                    <img src="/assets/images/onsector.png" width="895" height="674" alt="leader-board">
                                </div>
                            </div>
                        </div>

                    </SsrCarousel>
                </div>
            </section> -->


            <!-- <section class="aboutUsVideo ">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="header">
                                <h2>Onsectetur Adipiscing Elit Curabitur </h2>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing
                                    elit..Suspendisse eleifend sapien et ex ullamcorper, nec tempus
                                    lectus pretiumL
                                </p>
                            </div>
                            <div class="videoContainer">
                                <iframe width="100%" height="auto"
                                    src="https://www.youtube.com/embed/LXb3EKWsInQ?si=KH5DVIBiSqOKzwOX"
                                    title="YouTube video player" frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </section> -->






        </div>
        <appFooter />
    </div>

</template>

<script>
import SsrCarousel from "vue-ssr-carousel";
import "vue-ssr-carousel/index.css";
import pageHeader from "../components/pageHeader.vue";
import appFooter from "../layout/landing/appFooter.vue";

export default {
    components: {
        SsrCarousel,
        pageHeader,
        appFooter,
    },

    metaInfo: {
        title: "About Us | KIPS VIRTUAL",
        titleTemplate: "",
        htmlAttrs: {
            lang: "en",
            amp: true,
        },
    },

    mounted() {
        window.scrollTo(0, 0);
    }
};





</script>
