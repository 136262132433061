<template>
  <div>
    <div class="body-wrapper blog-detail-page ">
      <section class="blogDetailBodyWrapper">
        <div class="container">
          <div class="row">
            <div class="col-lg-9">
            </div>
          </div>
        </div>

        <div class="bodydetailContainer" v-if="this.blog">
          <div class="container">

            <div class="row">
              <div class="col-lg-9">
                <div class="blogTop">
                  <h1>{{ blog.title }}</h1>
                  <p v-html="blog.excerpt"></p>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-9">
                <div class="detailWrapper" v-if="blog">


                  <span class="blogDetDate">{{ convertToCustomFormat(blog.date) }}</span>
                  <div class="detailImg">
                    <img :src="blog.featured_image_src" alt="" width="614" height="364" />
                  </div>
                  <div v-html="blog.content"></div>
                  <!-- <div class="socialMediaIcons">
                    <span>- SHARE THIS</span>
                    <ul>
                      <li>
                        <a href="#"><img src="/assets/images/facebook-blog.svg" alt="" width="46" height="46"></a>
                      </li>
                      <li>
                        <a href="#"><img src="/assets/images/twitter-blog.svg" alt="" width="46" height="46"></a>
                      </li>
                      <li>
                        <a href="#"><img src="/assets/images/whatsapp-blog.svg" alt="" width="46" height="46"></a>
                      </li>
                    </ul>
                  </div> -->
                </div>
              </div>
              <div class="recentPostsWrap col-lg-3">
                <div class="recentPosts" v-if="recentPosts.length">
                  <h4>Recent Posts</h4>
                  <div v-for="recentPost in recentPosts" :key="recentPost.id" class="postWrapper"
                    @click="goToBlogDetail(recentPost.id)">
                    <h5>{{ convertToCustomFormat(recentPost.date) }}</h5>
                    <span>{{ recentPost.title }}</span>
                  </div>
                  <div class="recentPostBtn">
                    <button @click="goToBlogList" class="btn-1 secondary-btn">View More</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <appFooter />
  </div>
</template>

<script>
import pageHeader from "../../components/pageHeader.vue";
import appFooter from "../../layout/landing/appFooter.vue";
import { mapGetters, mapActions } from "vuex";
import { blogs as blogsStoreKeys } from "../../store/keys";

export default {
  components: {
    pageHeader,
    appFooter,
  },
  data() {
    return {
      blogId: null,
    };
  },
  metaInfo: {
    title: "BlogDetail | KIPS VIRTUAL",
    titleTemplate: "",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },
  computed: {
    ...mapGetters(blogsStoreKeys.namespace, blogsStoreKeys.getters),

    blog() {
      if (this.getBlogs.length) {
        const updatedBlog = this.getBlogs.find(blog => blog.id === parseInt(this.$route.params.id));
        if (updatedBlog) {
          return updatedBlog
        }
        else {
          this.$router.push({ name: "landingPageNotFound" }).catch(() => { });
        }
      }
    },
    recentPosts() {
      return this.getBlogs.slice(0, 4);
    },
  },
  mounted() {
    this.fetchBlogData(this.$route.params.id);
  },
  methods: {
    ...mapActions(blogsStoreKeys.namespace, blogsStoreKeys.actions),

    convertToCustomFormat(isoString) {
      const date = new Date(isoString);
      if (isNaN(date)) return "N/A";
      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      const day = String(date.getDate()).padStart(2, '0');
      const month = monthNames[date.getMonth()];
      const year = date.getFullYear();
      return `${day} ${month}, ${year}`;
    },

    async fetchBlogData(blogId) {
      this.blogId = blogId;
      await this.fetch_Blogs();
      window.scrollTo(0, 0);
    },

    goToBlogDetail(id) {
      this.$router.replace(`/blogs/detail/${id}`);
      window.scrollTo(0, 0);
    },

    goToBlogList() {
      this.$router.push('/blogs');
    }
  },

};
</script>
