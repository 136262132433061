<template>
  <div>
    <page-header heading="Virtual Talent  Activity Zone"
      desc="An engaging platform where students can join free educational activities, compete globally, and win scholarships, certificates, and exciting prizes." />
    <section class="special-courses">
      <div class="container">
        <div class="row" v-if="specialCourses && specialCourses.length">
          <div class="col-lg-12 col-md-6 mb-40" v-for="(courseListItem, index) in specialCourses" :key="index">
            <course-card :model="courseListItem" />
          </div>
        </div>
        <div class="load-more">
          <a v-if="canLoadMore" id="btnLoadMore" @click="loadMore" href="javascript:void(0)">
            <span class="load-more">Load More</span>
          </a>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>

<script>
import courseCard from "../../components/courseCard.vue";
import { mapGetters, mapActions } from "vuex";
import { exam as examStoreKeys, course as courseStoreKeys, classCourses as classCoursesStoreKeys, specialCourses as specialCoursesStoreKeys } from "../../store/keys";
import pageHeader from "../../components/pageHeader.vue";
import { appConstants } from "../../common/constants";
import Footer from "../../layout/landing/appFooter.vue";
// import cartConfirmationModal from "../cartConfirmationModal.vue";
// import { mapGetters, mapActions } from "vuex";
// import { appConstants } from "../common/constants";
// import featuredLoader from "../components/featuredLoader.vue";
// import { courseDetail as courseDetailStoreKeys, course as courseStoreKeys, featuredCourses as featuredCoursesStoreKeys, cart as cartStoreKeys } from "../store/keys";
export default {
  metaInfo() {
    return {
      title: "Activities | KIPS VIRTUAL",
      titleTemplate: "",
      htmlAttrs: {
        lang: "en",
        amp: true,
      },
    };
  },
  components: {
    courseCard,
    pageHeader,
    Footer,
    // featuredLoader
  },
  mounted() {
    window.scrollTo(0, 0);
    if (this.$route.hash) {
      var elm = this.$route.hash.slice(1);
      this.scrollToElement(elm);
    }
    this.getSpecialCourses();
    let routeParams = this.$route.params;
    // this.getExamsGroups("medical");
    window.addEventListener("scroll", () => {
      this.scrollY = Math.round(window.scrollY);
      if (this.$refs.header) {
        this.headerTop = document.getElementById("header").offsetTop;
        if (this.scrollY > this.headerTop) {
          this.isHeaderSticky = true;
          document.body.classList.add("exam-body");
        } else {
          this.isHeaderSticky = false;
          document.body.classList.remove("exam-body");
        }
      }
    });
  },
  data() {
    return {
      selectedCourseGroupCategory: {
        Id: 0,
        Name: "",
      },
      coursePaging: {
        pageNumber: 1,
        pageSize: 12,
        showRecordsNotFound: false,
      },

      settings: {
        arrows: false,
        autoplay: false,
        autoplaySpeed: 2000,
        dots: false,
        focusOnSelect: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        touchThreshold: 5,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              dots: true,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              dots: true,
            },
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(examStoreKeys.namespace, examStoreKeys.getters),
    ...mapGetters(courseStoreKeys.namespace, courseStoreKeys.getters),
    ...mapGetters(classCoursesStoreKeys.namespace, classCoursesStoreKeys.getters),
    ...mapGetters(specialCoursesStoreKeys.namespace, specialCoursesStoreKeys.getters),
  },
  methods: {
    ...mapActions(examStoreKeys.namespace, examStoreKeys.actions),
    ...mapActions(courseStoreKeys.namespace, courseStoreKeys.actions),
    ...mapActions(classCoursesStoreKeys.namespace, classCoursesStoreKeys.actions),
    ...mapActions(specialCoursesStoreKeys.namespace, specialCoursesStoreKeys.actions),
    getExamsGroups(item) {
      return this.get_Exams_Groups({
        config: {
          loader: {
            show: true,
            id: "examCoursesLoader",
          },
        },
      })
        .then((response) => {
          if (this.sosCategories.length > 0) {
            var category = this.sosCategories.find((c) => c.CourseGroupName.toLowerCase() == item);
            this.updateSelectedsosCategory(category || this.sosCategories[0]);
          }
        })
        .catch((ex) => {
          this.$alertError(ex.message);
        });
    },
    loadMore() {
      this.coursePaging.pageNumber++;
      this.getSpecialCourses(appConstants.enums.pagingMode.loadMore);
    },
    updateSelectedsosCategory(sosCategory) {
      this.selectedCourseGroupCategory.Id = sosCategory.CourseGroupId;
      this.selectedCourseGroupCategory.Name = sosCategory.CourseGroupName;
      this.coursePaging.pageNumber = 1;
      //this.resetClassCourses();
      this.getClassCourses();
    },
    // resetClassCourses() {
    //   this.reset_ClassCourses().then((response) => {
    //     this.coursePaging.pageNumber = 1;
    //     this.getClassCourses();
    //   });
    // },
    getSpecialCourses(pagingMode) {
      this.get_SpecialCourses({
        data: {
          CourseListFilter: 2,
          // CourseGroupId: this.selectedCourseGroupCategory.Id,
          ProgramCategoryId: appConstants.enums.programCategory.All,
          PageNumber: this.coursePaging.pageNumber,
          PageSize: this.coursePaging.pageSize,
          ApplyDefaultTagFilter: false,
        },
        config: {
          loader: {
            show: true,
            id: "examCoursesLoader",
          },
        },
        pagingMode: pagingMode,
      })
        .then((response) => {
          this.showRecordsNotFound = true;
          // this.coursePaging.pageNumber = response.PageNumber;
        })
        .catch((ex) => {
          this.$alertError(ex.message);
        });
    },
  },
};
</script>
<style scoped>
.load-more {
  text-align: center;
  cursor: pointer;
}

.load-more i {
  background: #fff;
  padding: 9px 10px;
  border-radius: 50%;
  font-size: 17px;
  box-shadow: 0 0 10px 0px #d8d8d8;
}
</style>
