<template>
    <div>
        <div class="blog-cards-page">
            <section class="page-header-section">
                <div class="circle-pattern-3"><img src="/assets/images/banner/circle-pattern-3.svg" width="200" alt="">
                </div>
                <div class="container">
                    <div class="row">
                        <div class="col-md-8 col-lg-7"><!---->
                            <h2 class="heading">Blogs</h2> <span class="desc d-block">Discover valuable insights, study
                                strategies, and guidance through our blogs to help you excel with KIPS Virtual</span>
                        </div>
                    </div>
                </div>
            </section>
            <section class="blogCardsWrap">
                <template>
                    <div v-if="loading" class="loading-state">
                        <div class="d-flex justify-content-center align-items-center" style="min-height: 100px;">
                            <div class="text-center w-5 h-5">
                                <span class="spinner-border"></span>
                            </div>
                        </div>
                    </div>
                    <div v-else class="container">
                        <div class="row">
                            <div v-for="(blog) in visibleBlogs" :key="blog.id" class="col-md-6 blog-card">
                                <router-link :to="`/blogs/detail/${blog.id}`">
                                    <img class="cardImage" :src="blog.featured_image_src" alt="" width="605"
                                        height="343">
                                    <div class="blogCardText">
                                        <div class="eventDate">
                                            <h2>{{ convertToCustomFormat(blog.date) }}</h2>
                                        </div>
                                        <div class="eventDetail">
                                            <h3>{{ blog.title }}</h3>
                                            <div class="blogStartDetails" v-html="blog.excerpt"></div>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                        <div v-if="getBlogs.length > visibleCount" class="text-center mt-4 blogLoadMoreBtn">
                            <a href="javascript:void(0)" class="btn-1 secondary-btn" @click="loadMore">Load More</a>
                        </div>
                    </div>
                </template>

            </section>
            <!-- <div class="blog-pagination-arrows d-flex justify-content-center ">
                <a href="#" class="arrow-left"><svg xmlns="http://www.w3.org/2000/svg" width="5.333" height="8.274"
                        viewBox="0 0 5.333 8.274">
                        <path id="Path_46729" data-name="Path 46729"
                            d="M1185.869,591.952a.63.63,0,0,1-.189-.44.742.742,0,0,1,.189-.44l3.508-3.517a.741.741,0,0,1,.444-.181.617.617,0,0,1,.435.181l.568.595a.6.6,0,0,1,.189.44.529.529,0,0,1-.189.44l-2.478,2.482,2.478,2.482a.6.6,0,0,1,.189.44.531.531,0,0,1-.189.44l-.568.595a.527.527,0,0,1-.435.181.614.614,0,0,1-.444-.181Z"
                            transform="translate(-1185.68 -587.375)" />
                    </svg>
                </a>
                <a href="#" class="arrow-right"><svg xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" width="5.333" height="8.274"
                        viewBox="0 0 5.333 8.274">
                        <defs>
                            <clipPath id="clip-path">
                                <rect width="5.333" height="8.274" fill="none" />
                            </clipPath>
                        </defs>
                        <g id="Repeat_Grid_1" data-name="Repeat Grid 1" clip-path="url(#clip-path)">
                            <g transform="translate(-1262.259 -2682.75)">
                                <path id="Path_46729" data-name="Path 46729"
                                    d="M1190.824,591.952a.63.63,0,0,0,.189-.44.742.742,0,0,0-.189-.44l-3.508-3.517a.741.741,0,0,0-.444-.181.617.617,0,0,0-.435.181l-.568.595a.6.6,0,0,0-.189.44.529.529,0,0,0,.189.44l2.478,2.482-2.478,2.482a.6.6,0,0,0-.189.44.531.531,0,0,0,.189.44l.568.595a.527.527,0,0,0,.435.181.614.614,0,0,0,.444-.181Z"
                                    transform="translate(76.579 2095.375)" />
                            </g>
                        </g>
                    </svg>
                </a>
            </div> -->
        </div>
        <appFooter />
    </div>
</template>

<script>
import pageHeader from "../components/pageHeader.vue";
import appFooter from "../layout/landing/appFooter.vue";
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import { blogs as blogsStoreKeys } from "../store/keys";
export default {
    components: {
        pageHeader,
        appFooter,
    },
    data() {
        return {
            visibleCount: 4, // Initially visible blog count
            loading: true,
        };
    },
    metaInfo: {
        title: "Blogs | KIPS VIRTUAL",
        titleTemplate: "",
        htmlAttrs: {
            lang: "en",
            amp: true,
        },
    },
    mounted() {
        this.fetch_Blogs().then(() => {
            this.loading = false;
        });
        window.scrollTo(0, 0);
    },
    computed: {
        ...mapGetters(blogsStoreKeys.namespace, blogsStoreKeys.getters),
        visibleBlogs() {
            return this.getBlogs.slice(0, this.visibleCount);
        },
    },
    methods: {
        ...mapActions(blogsStoreKeys.namespace, blogsStoreKeys.actions),
        loadMore() {
            this.visibleCount += 4;
        },
        convertToCustomFormat(isoString) {
            const date = new Date(isoString);
            if (isNaN(date)) return "N/A";
            const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            const day = String(date.getDate()).padStart(2, '0');
            const month = monthNames[date.getMonth()];
            return `${day} ${month}`;
        }
    },
};
</script>